import React, { useEffect, useState } from "react";
import "./partydetails.css";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initialPartyDetails, partyDetailsSchema } from "../../validations";
import { usePartyDetails, useGuestListFile } from "../../hooks/partydetails";
import {  BeatLoader, ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../utls/Context";

const Partdetails = () => {
  const navigate = useNavigate();
  const {logout}= useStateContext()

  const { mutateAsync, isLoading, isSuccess, isError, data, error } =
    usePartyDetails();
  const {
    mutateAsync: guestAsync,
    isLoading: isGuestLoading,
    data: guest,
  } = useGuestListFile();

  const [filePreviewURL, setFilePreviewURL] = useState("");

  useEffect(() => {
    if (!guest) {
      setFilePreviewURL(""); // Reset file preview when uploading a new file
    }
  }, [guest]);


  const {
    resetForm,
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialPartyDetails,
    validationSchema: partyDetailsSchema,
    onSubmit: async (values) => {
      try {
        const response = await mutateAsync({
          email: values.email,
          eventBookBy: values.eventBookBy,
          dateOfEvent: values.dateOfEvent,
          typeOfEvent: values.typeOfEvent,
          designatedArea: values.designatedArea,
          nameOfCustomer: values.nameOfCustomer,
          nameOfVendor: values.nameOfVendor,
          phone: values.phone,
          activitiesZipline: values.activitiesZipline,
          activitiesZipcycle: values.activitiesZipcycle,
          activitiesBullRide: values.activitiesBullRide,
          activitiesBodyZorbing: values.activitiesBodyZorbing,
          activitiesLaserTag: values.activitiesLaserTag,
          activitiesMagicShow: values.activitiesMagicShow,
          activitiesBallonArt: values.activitiesBallonArt,
          activitiesTattooPaintaing: values.activitiesTattooPaintaing,
          activitiesPottery: values.activitiesPottery,
          activitiesDJ: values.activitiesDJ,
          activitiesBartender: values.activitiesBartender,
          noAdults: values.noAdults,
          noKids: values.noKids,
          adultPrice: values.adultPrice,
          kidsPrice: values.kidsPrice,
          otherService: values.otherService,
          totalBillingAmount: values.totalBillingAmount,
          DateOfAdvance: values.DateOfAdvance,
          advanceAmount: values.advanceAmount,
          ModeOFAdvancePayment: values.ModeOFAdvancePayment,
          finalAmountPaid: values.finalAmountPaid,
          modeOfFinalPayment: values.modeOfFinalPayment,
          discount: values.discount,
          serviceIssues: values.serviceIssues,
          eventCoordinator: values.eventCoordinator,
          paymentCollectedBy: values.paymentCollectedBy,
          staffAvailableInParty: values.staffAvailableInParty,
          UplaoadGuestList: values.UplaoadGuestList,
        });

        
        // toast.success("party details added successfully!");
        console.log('response',response)
      } catch (error) {
        console.error("Error during login:", error);
        // toast.error("Something went wrong");
      }
      
    },
  });
 


  useEffect(() => {
    if (isSuccess) {
      const { status, message } = data;
      toast.success(message);

      if (status === 200) {
        resetForm();
        document.getElementById("UplaoadGuestList").value = "";
      }
    }

    if (isError) {
      console.log("errorthet", error);
      toast.error(error.response.data.message);
      if (error?.response?.data?.status === 401) {
        navigate("/");
        logout();
      }
    }
  }, [isError, isSuccess, data, error,navigate, resetForm,logout]);

  useEffect(() => {
    if (values.UplaoadGuestList instanceof File) {
      const formData = new FormData();
      formData.append("UplaoadGuestList", values.UplaoadGuestList);

      guestAsync(formData)
        .then((response) => {
          console.log("Guest list uploaded successfully");
        })
        .catch((error) => {
          console.error("Error uploading guest list:", error);
        });
    }
  }, [values.UplaoadGuestList, guestAsync]);

  useEffect(() => {
    setFieldValue("UplaoadGuestList", guest?.data?.filename);
  }, [guest, values, setFieldValue]);
 

  useEffect(() => {
    if (values.UplaoadGuestList instanceof File) {
      const url = URL.createObjectURL(values.UplaoadGuestList);
      setFilePreviewURL(url);
    }
  }, [values.UplaoadGuestList]);

  return (
    <div>
      <div className="party">
        {/* <Navbar /> */}
        <div className="partydetails">
          <div className="title">
            <h2>Party details</h2>
          </div>
          <div className="partycontent">
            <div className="partywrapper">
              <form onSubmit={handleSubmit}>
                <div className="partydetaillabel">
                  <div className="label">
                    <label>Email Address</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>
                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Email Address"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email && (
                      <div className="error-message">{errors.email}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Party Name </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>
                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Event Booked By"
                      name="eventBookBy"
                      value={values.eventBookBy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.eventBookBy && errors.eventBookBy && (
                      <div className="error-message">{errors.eventBookBy}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Date Of Event</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>
                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Date Of Event "
                      name="dateOfEvent"
                      value={values.dateOfEvent}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.dateOfEvent && errors.dateOfEvent && (
                      <div className="error-message">{errors.dateOfEvent}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Type of Event</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Type od Event"
                      name="typeOfEvent"
                      value={values.typeOfEvent}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {touched.typeOfEvent && errors.typeOfEvent && (
                      <div className="error-message">{errors.typeOfEvent}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Designated Area for Event</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Designated Area for Event"
                      name="designatedArea"
                      value={values.designatedArea}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {touched.designatedArea && errors.designatedArea && (
                      <div className="error-message">
                        {errors.designatedArea}
                      </div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Name of Customer/School</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>
                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Name of Customer/School"
                      name="nameOfCustomer"
                      value={values.nameOfCustomer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {touched.nameOfCustomer && errors.nameOfCustomer && (
                      <div className="error-message">
                        {errors.nameOfCustomer}
                      </div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Name of TA/Vendor</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Name of TA/Vendor"
                      name="nameOfVendor"
                      value={values.nameOfVendor}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {touched.nameOfVendor && errors.nameOfVendor && (
                      <div className="error-message">{errors.nameOfVendor}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label> Phone Number</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Phone Number of Customer/Vendor"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {touched.phone && errors.phone && (
                      <div className="error-message">{errors.phone}</div>
                    )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[Zipline]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesZipline"
                          checked={values.activitiesZipline === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesZipline"
                          checked={values.activitiesZipline === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesZipline && errors.activitiesZipline && (
                      <div className="error-message">
                        {errors.activitiesZipline}
                      </div>
                    )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[Zipcycle]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesZipcycle"
                          checked={values.activitiesZipcycle === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesZipcycle"
                          checked={values.activitiesZipcycle === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesZipcycle &&
                      errors.activitiesZipcycle && (
                        <div className="error-message">
                          {errors.activitiesZipcycle}
                        </div>
                      )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[Meltdown]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesBullRide"
                          checked={values.activitiesBullRide === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesBullRide"
                          checked={values.activitiesBullRide === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesBullRide &&
                      errors.activitiesBullRide && (
                        <div className="error-message">
                          {errors.activitiesBullRide}
                        </div>
                      )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[Body Zorbing]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesBodyZorbing"
                          checked={values.activitiesBodyZorbing === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesBodyZorbing"
                          checked={values.activitiesBodyZorbing === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesBodyZorbing &&
                      errors.activitiesBodyZorbing && (
                        <div className="error-message">
                          {errors.activitiesBodyZorbing}
                        </div>
                      )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[Laser Tag]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesLaserTag"
                          checked={values.activitiesLaserTag === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesLaserTag"
                          checked={values.activitiesLaserTag === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesLaserTag &&
                      errors.activitiesLaserTag && (
                        <div className="error-message">
                          {errors.activitiesLaserTag}
                        </div>
                      )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[Magic Show]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesMagicShow"
                          checked={values.activitiesMagicShow === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesMagicShow"
                          checked={values.activitiesMagicShow === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesMagicShow &&
                      errors.activitiesMagicShow && (
                        <div className="error-message">
                          {errors.activitiesMagicShow}
                        </div>
                      )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[Ballon Art]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesBallonArt"
                          checked={values.activitiesBallonArt === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesBallonArt"
                          checked={values.activitiesBallonArt === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesBallonArt &&
                      errors.activitiesBallonArt && (
                        <div className="error-message">
                          {errors.activitiesBallonArt}
                        </div>
                      )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[Tattoo Paintaing]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesTattooPaintaing"
                          checked={values.activitiesTattooPaintaing === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesTattooPaintaing"
                          checked={values.activitiesTattooPaintaing === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesTattooPaintaing &&
                      errors.activitiesTattooPaintaing && (
                        <div className="error-message">
                          {errors.activitiesTattooPaintaing}
                        </div>
                      )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[Pottery]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesPottery"
                          checked={values.activitiesPottery === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesPottery"
                          checked={values.activitiesPottery === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesPottery && errors.activitiesPottery && (
                      <div className="error-message">
                        {errors.activitiesPottery}
                      </div>
                    )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[DJ]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesDJ"
                          checked={values.activitiesDJ === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesDJ"
                          checked={values.activitiesDJ === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesDJ && errors.activitiesDJ && (
                      <div className="error-message">{errors.activitiesDJ}</div>
                    )}
                  </div>
                </div>

                <div className="radiowrapperclass">
                  <div className="label">
                    <label for="yes_no_radio">
                      Activities Included In the Package[Bartender]
                    </label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <div className="radiowrapper">
                      <p>
                        <input
                          type="radio"
                          name="activitiesBartender"
                          checked={values.activitiesBartender === "Yes"}
                          value="Yes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Yes
                      </p>
                      <p>
                        <input
                          type="radio"
                          name="activitiesBartender"
                          checked={values.activitiesBartender === "No"}
                          value="No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        No
                      </p>
                    </div>
                    {touched.activitiesBartender &&
                      errors.activitiesBartender && (
                        <div className="error-message">
                          {errors.activitiesBartender}
                        </div>
                      )}
                  </div>
                </div>
                <div className="partydetaillabel">
                  <div className="label">
                    <label>No of Adults</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter No of Adults"
                      name="noAdults"
                      value={values.noAdults}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.noAdults && errors.noAdults && (
                      <div className="error-message">{errors.noAdults}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>No of Kids</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter No of Kids"
                      name="noKids"
                      value={values.noKids}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.noKids && errors.noKids && (
                      <div className="error-message">{errors.noKids}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Adult Price</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Adult Price As quoted in FP"
                      name="adultPrice"
                      value={values.adultPrice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.adultPrice && errors.adultPrice && (
                      <div className="error-message">{errors.adultPrice}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Kids Price</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Kids Price as quoted in FP"
                      name="kidsPrice"
                      value={values.kidsPrice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.kidsPrice && errors.kidsPrice && (
                      <div className="error-message">{errors.kidsPrice}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Any other Services</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Any other Services"
                      name="otherService"
                      value={values.otherService}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.otherService && errors.otherService && (
                      <div className="error-message">{errors.otherService}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Total Billing Amount</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Total Billing Amount"
                      name="totalBillingAmount"
                      value={values.totalBillingAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.totalBillingAmount &&
                      errors.totalBillingAmount && (
                        <div className="error-message">
                          {errors.totalBillingAmount}
                        </div>
                      )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Date of Advance</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Date of Advance"
                      name="DateOfAdvance"
                      value={values.DateOfAdvance}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.DateOfAdvance && errors.DateOfAdvance && (
                      <div className="error-message">
                        {errors.DateOfAdvance}
                      </div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Advance Amount</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Advance Amount"
                      name="advanceAmount"
                      value={values.advanceAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.advanceAmount && errors.advanceAmount && (
                      <div className="error-message">
                        {errors.advanceAmount}
                      </div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Mode of Payment</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <select
                      name="ModeOFAdvancePayment"
                      value={values.ModeOFAdvancePayment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Choose Mode of Payment</option>

                      <option value="cash">Cash</option>
                      <option value="upi">Upi</option>
                      <option value="rozarPay">Rozarpay</option>
                      <option value="bank_transfer">Bank Transfer</option>
                      <option value="cheque">Cheque</option>
                    </select>
                    {touched.ModeOFAdvancePayment &&
                      errors.ModeOFAdvancePayment && (
                        <div className="error-message">
                          {errors.ModeOFAdvancePayment}
                        </div>
                      )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Final Amount Paid</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Final Amount Paid"
                      name="finalAmountPaid"
                      value={values.finalAmountPaid}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.finalAmountPaid && errors.finalAmountPaid && (
                      <div className="error-message">
                        {errors.finalAmountPaid}
                      </div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Mode of Payment</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <select
                      name="modeOfFinalPayment"
                      value={values.modeOfFinalPayment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Choose Mode of Payment</option>

                      <option value="cash">Cash</option>
                      <option value="upi">Upi</option>
                      <option value="rozarPay">Rozarpay</option>
                      <option value="bank_transfer">Bank Transfer</option>
                      <option value="cheque">Cheque</option>
                    </select>
                    {touched.modeOfFinalPayment &&
                      errors.modeOfFinalPayment && (
                        <div className="error-message">
                          {errors.modeOfFinalPayment}
                        </div>
                      )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Discount</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Discount"
                      name="discount"
                      value={values.discount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.discount && errors.discount && (
                      <div className="error-message">{errors.discount}</div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Describe Service Issues</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Describe Service issue"
                      name="serviceIssues"
                      value={values.serviceIssues}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.serviceIssues && errors.serviceIssues && (
                      <div className="error-message">
                        {errors.serviceIssues}
                      </div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Event Coordinator</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Event Coordinator"
                      name="eventCoordinator"
                      value={values.eventCoordinator}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.eventCoordinator && errors.eventCoordinator && (
                      <div className="error-message">
                        {errors.eventCoordinator}
                      </div>
                    )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Final Payment Collected By</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Final Payment collected by"
                      name="paymentCollectedBy"
                      value={values.paymentCollectedBy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.paymentCollectedBy &&
                      errors.paymentCollectedBy && (
                        <div className="error-message">
                          {errors.paymentCollectedBy}
                        </div>
                      )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Staff Available in Party</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="text"
                      placeholder="Enter Staff Available in Party"
                      name="staffAvailableInParty"
                      value={values.staffAvailableInParty}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.staffAvailableInParty &&
                      errors.staffAvailableInParty && (
                        <div className="error-message">
                          {errors.staffAvailableInParty}
                        </div>
                      )}
                  </div>
                </div>

                <div className="partydetaillabel">
                  <div className="label">
                    <label>Upload Guest List</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>

                  <div className="partyinput" style={{}}>
                    <input
                      type="file"
                      placeholder="Upload Guest List"
                      name="UplaoadGuestList"
                      id="UplaoadGuestList"
                      // value={values.UplaoadGuestList}
                      onChange={(e) =>
                        setFieldValue(
                          "UplaoadGuestList",
                          e.currentTarget.files[0]
                        )
                      }
                      onBlur={handleBlur}
                    />

                    {isGuestLoading && (
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "-25px",
                        }}
                      >
                        <ClipLoader size={20} />
                      </div>
                    )}
                    {touched.UplaoadGuestList && errors.UplaoadGuestList && (
                      <div className="error-message">
                        {errors.UplaoadGuestList}
                      </div>
                    )}

                    {filePreviewURL && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          style={{
                            background: "unset",
                            border: "unset",
                            textAlign: "right",
                            fontWeight: "600",
                          }}
                          onClick={() => window.open(filePreviewURL)}
                        >
                          Preview File
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="partydetaillabel">
                  <div className="label">
                    <label>Staff Available in Party</label>
                    <label style={{ marginRight: "10px" }}>:</label>
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Staff Available in Party"
                  />
                </div> */}

                <button type="submit" className="btt"
                            disabled={isLoading} 
>
                  {" "}
                  {isLoading ? (
                    <BeatLoader size={8} color={"#ffffff"} />
                  ) : (
                    "Add Party Details"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partdetails;
