import "./App.css";

import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./components/login/Login";
import Admin from "./Pages/Admin/Admin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OpertionalManager from "./Pages/opertionalmanager/OpertionalManager";
// import PointOfSale from './Pages/PointOfSale';
import AddEmployee from "./Pages/Admin/addemployee/AddEmployee";

import ForgetPassword2 from "./components/Forgetpassword/ForgetPassword2";
import Forgetpassword1 from "./components/Forgetpassword/Forgetpassword1";
import Forgetpassword3 from "./components/Forgetpassword/Forgetpassword3";
import AddReport from "./Pages/pointofsale/AddReport";
import FinancialManager from "./Pages/finicialmanager/FinancialManager";
// import SaleReport from "./Pages/Admin/saleReport/SaleReport";
import Partdetails from "./components/partyDetails/Partdetails";
import Index from "./Pages/pointofsale/Index";
import UploadFile from "./Pages/Admin/uploadfile/UploadFile";
// import SaleDetails from "./Pages/Admin/saleReport/SaleDetails";
import AddReportNonTicket from "./Pages/pointofsale/AddReportNonTicket";
import Navbar from "./components/Navbar/Navbar";
import PartyDetailsReport from "./Pages/Admin/saleReport/PartyDetailsReport";
import AddCounter from "./Pages/Admin/addcounter/AddCounter";
import CounterDetails from "./Pages/Admin/addcounter/CounterDetails";
import BandColour from "./Pages/Admin/bandcolour/BandColour";
import EditNonTicketCollector from "./Pages/Admin/editdsl/EditNonTicketCollector";
import EditTicketCollector from "./Pages/Admin/editdsl/EditTicketCollector";
import EditPartyDetails from "./Pages/Admin/editdsl/EditPartyDetails";
import Dashboard from "./Pages/Admin/dashboard/Dashboard";
import ComboType from "./Pages/Admin/combotype/ComboType";
import SaleReportDublicate from "./Pages/Admin/saleReport/SaleReportDublicate";
import SaleDetailsDublicate from "./Pages/Admin/saleReport/SaleDetailsDublicate";
import Posaddfrom from "./Pages/Admin/addemployee/AddEmployeeForm";
import ProtectiveRoutes, { AccessProtectedRoutes } from "./utls/ProtectiveRoutes";
import  Unauthorized  from "./components/unauthorized/Unauthorised";

function App() {
  const location = useLocation(); // Use useLocation to get the current path

  return (
    <div>
      {location.pathname !== '/unauthorized' && <Navbar />}

        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/forgetpassword1" element={<Forgetpassword1 />} />
          <Route exact path="/forgetpassword2" element={<ForgetPassword2 />} />
          <Route exact path="/forgetpassword3" element={<Forgetpassword3 />} />
          {/* <Route exact path="/pointofsale" element={<PointOfSale/>} /> */}
          <Route exact path="/addreport" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['POS Employee']}><AddReport /></AccessProtectedRoutes></ProtectiveRoutes>} />
          <Route
            exact
            path="/addreportnonticket"
            element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['POS Employee']}><AddReportNonTicket /></AccessProtectedRoutes></ProtectiveRoutes>}
          />

          <Route
            exact
            path="/financialmanager"
            element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Financial Manager']}><FinancialManager /></AccessProtectedRoutes></ProtectiveRoutes>}
          />
          <Route
            exact
            path="/opertionalmanager"
            element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Opertional Manager']}><OpertionalManager /></AccessProtectedRoutes></ProtectiveRoutes>}
          />
          <Route exact path="/index" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['POS Employee']}><Index /></AccessProtectedRoutes></ProtectiveRoutes>} />

          <Route exact path="/admin" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin']}><Admin /></AccessProtectedRoutes></ProtectiveRoutes>} />
          <Route exact path="/employee" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin']}><AddEmployee /></AccessProtectedRoutes></ProtectiveRoutes>} />
          <Route exact path="/employee/addemployee" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin']}><Posaddfrom/></AccessProtectedRoutes></ProtectiveRoutes>} />
          {/* <Route exact path="/salereport" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin','Opertional Manager','Financial Manager']}><SaleReport /></AccessProtectedRoutes></ProtectiveRoutes>} /> */}
          <Route exact path="/salereport" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin','Opertional Manager','Financial Manager']}><SaleReportDublicate /></AccessProtectedRoutes></ProtectiveRoutes>} />

          <Route exact path="/partydetails" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin','Opertional Manager']}><Partdetails /></AccessProtectedRoutes></ProtectiveRoutes>} />
          <Route exact path="/uploadfile" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin']}><UploadFile /></AccessProtectedRoutes></ProtectiveRoutes>} />
          {/* <Route exact path="/salereport/saledetails" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin','Opertional Manager','Financial Manager']}><SaleDetails /></AccessProtectedRoutes></ProtectiveRoutes>} /> */}
          <Route exact path="/salereport/saledetails" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin','Opertional Manager','Financial Manager']}><SaleDetailsDublicate /></AccessProtectedRoutes></ProtectiveRoutes>} />

          <Route
            exact
            path="/salereport/partydetailsreport"
            element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin','Opertional Manager','Financial Manager']}><PartyDetailsReport /></AccessProtectedRoutes></ProtectiveRoutes>}
          />
          <Route exact path="/counterdetails/addcounter" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin']}><AddCounter /></AccessProtectedRoutes></ProtectiveRoutes>} />
          <Route exact path="/counterdetails" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin']}><CounterDetails /></AccessProtectedRoutes></ProtectiveRoutes>} />
          <Route exact path="/bandcolour" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin']}><BandColour /></AccessProtectedRoutes></ProtectiveRoutes>} />
          <Route exact path="/combotype" element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin']}><ComboType /></AccessProtectedRoutes></ProtectiveRoutes>} />

          <Route
            exact
            path="/salereport/editnonticketcollector"
            element={<ProtectiveRoutes> <AccessProtectedRoutes allowedRoles={['Admin', 'Opertional Manager']}><EditNonTicketCollector /></AccessProtectedRoutes></ProtectiveRoutes>}
          />
          <Route
            exact
            path="/salereport/editticketcollector"
            element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin', 'Opertional Manager']}><EditTicketCollector /></AccessProtectedRoutes></ProtectiveRoutes>}
          />
          <Route
            exact
            path="/salereport/editpartydetails"
            element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin', 'Opertional Manager']}><EditPartyDetails /></AccessProtectedRoutes></ProtectiveRoutes>}
          />
           <Route
            exact
            path="/dashboard"
            element={<ProtectiveRoutes><AccessProtectedRoutes allowedRoles={['Admin']}><Dashboard/></AccessProtectedRoutes></ProtectiveRoutes>}
          />
        <Route exact path="/unauthorized" element={<Unauthorized />} />
        </Routes>

        <ToastContainer />
    </div>
  );
}

export default App;
