import React, { useEffect } from "react";
import "./forgetpassword.css";
import { Link, useNavigate } from "react-router-dom";
import { useForgetPassword } from "../../hooks/forgetpassword";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";

import { initialforgetpasword, forgetPasswordSchema } from "../../validations";

const Forgetpassword1 = () => {
  const navigate = useNavigate();
  const { mutateAsync, isLoading, isSuccess, isError, data, error } =
    useForgetPassword();

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === 200) {
        toast.success(data?.message);

        localStorage.setItem("phoneNumber", values.phone);
        navigate("/forgetpassword2");
        resetForm();
      } else {
        toast.error(data?.message);
      }
    } else if (isError) {
      toast.error(error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        navigate("/");
      }
    }
    // eslint-disable-next-line
  }, [isError, isSuccess, data, navigate]);

  const {
    errors,
    resetForm,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: {
      ...initialforgetpasword,
      purpose: "forgetpassword",
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: async (values) => {
      try {
        const response = await mutateAsync(values);
        console.log("🚀 ~ onSubmit: ~ response?.data:", response);
      } catch (error) {
        console.error("Error during phone:", error);
        // toast.error("Something went wrong");
      }
    },
  });

  return (
    <div>
      <div className="forgetpage">
        <div className="forget">
          <form onSubmit={handleSubmit}>
            <div className="forminput">
              <label> Enter Your Phone Number*</label>
              <input
                type="text"
                placeholder="Enter your Phone Number"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.phone && errors.phone && (
                <div className="error-messages">{errors.phone}</div>
              )}
            </div>
            <button className="btt" type="submit" disabled={isLoading}>
              {isLoading ? <BeatLoader size={8} color={"#ffffff"} /> : "Next"}
            </button>
          </form>
          <Link to="/" style={{ textDecoration: "none" }}>
            <p className="forgetback">Back to login page</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword1;
