import React from 'react'
import { Navigate } from 'react-router-dom'
// import { useStateContext } from './Context';

function ProtectiveRoutes({children}) {
    const userId = localStorage.getItem("user_id")
    if (!userId) {
        return <Navigate to="/" />
    }

  return children;
}

export const AccessProtectedRoutes = ({ children, allowedRoles }) => {
  // const { logout } = useStateContext();
  const usertype = localStorage.getItem("employeetype");
  // const location = useLocation(); // Get the current location

  if (!allowedRoles.includes(usertype)) {
    // alert("You do not have permission to access this page.");
    // logout();
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectiveRoutes