import React from 'react'
import { useNavigate } from 'react-router-dom';
import './unauthorised.css'

const Unauthorised = () => {
    const navigate = useNavigate();

    const handleBack = () => {
     navigate('/');
    };
  
    return (
      <div className="unauthorised_container">
        <h1 className="unauthorised_heading">Unauthorized Access</h1>
        <p className="unauthorised_message">
          You do not have permission to view this page. Please check your access rights or contact support.
        </p>
        <button onClick={handleBack} className="unauthorised_button">
          Go Back
        </button>
      </div>
    );
}

export default Unauthorised
